.loader {
  width: 75px;
  height: 75px;
  margin: 1rem auto;
  -webkit-animation: fadeinout 2s infinite;
  animation: fadeinout 2s infinite;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
